<template>
  <svg
    width="64"
    height="32"
    viewBox="0 0 64 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2C0 0.895431 0.895431 0 2 0H62C63.1046 0 64 0.895431 64 2V30C64 31.1046 63.1046 32 62 32H2C0.895431 32 0 31.1046 0 30V2Z"
      fill="#003C3C"
    />
    <path
      d="M22.3494 11.8398H23.9572V14.7698C24.3704 14.2292 25.083 13.8849 25.9334 13.8849C27.3691 13.8849 28.4158 14.849 28.4158 16.6083V20.3438H26.8079V16.8286C26.8079 15.8061 26.2433 15.2552 25.3929 15.2552C24.5184 15.2552 23.9572 15.8061 23.9572 16.8286V20.3438H22.3494V11.8398Z"
      fill="white"
    />
    <path
      d="M35.6668 20.3435H34.0452V19.5378C33.6423 20.0784 32.9434 20.4227 32.1481 20.4227C30.6642 20.4227 29.5969 19.4587 29.5969 17.7097V13.9775H31.2082V17.4824C31.2082 18.505 31.7728 19.0558 32.6232 19.0558C33.4977 19.0558 34.0486 18.505 34.0486 17.4824V13.9775H35.6702V20.3435H35.6668Z"
      fill="white"
    />
    <path
      d="M39.4711 13.8746C40.3112 13.8746 41.1134 14.2637 41.5506 14.8627V11.8398H43.1825V20.3438H41.5506V19.4005C41.1719 19.9858 40.4592 20.4471 39.4608 20.4471C37.8391 20.4471 36.5549 19.1251 36.5549 17.1385C36.5515 15.1485 37.8391 13.8746 39.4711 13.8746ZM39.8739 15.2862C39.0132 15.2862 38.1972 15.93 38.1972 17.135C38.1972 18.3401 39.0132 19.0321 39.8739 19.0321C40.7587 19.0321 41.5644 18.3642 41.5644 17.1591C41.5644 15.9541 40.7587 15.2862 39.8739 15.2862Z"
      fill="white"
    />
    <path
      d="M46.9767 13.8746C47.8168 13.8746 48.619 14.2637 49.0562 14.8627V11.8398H50.6882V20.3438H49.0562V19.4005C48.6775 19.9858 47.9648 20.4471 46.9664 20.4471C45.3448 20.4471 44.0606 19.1251 44.0606 17.1385C44.0571 15.1485 45.3448 13.8746 46.9767 13.8746ZM47.3761 15.2862C46.5153 15.2862 45.6994 15.93 45.6994 17.135C45.6994 18.3401 46.5153 19.0321 47.3761 19.0321C48.2609 19.0321 49.0665 18.3642 49.0665 17.1591C49.0665 15.9541 48.2609 15.2862 47.3761 15.2862Z"
      fill="white"
    />
    <path
      d="M51.9758 11.8398H53.5837V20.3438H51.9758V11.8398Z"
      fill="white"
    />
    <path
      d="M57.6635 20.4471C55.8147 20.4471 54.4685 19.1594 54.4685 17.1591C54.4685 15.1484 55.7768 13.8711 57.6635 13.8711C59.502 13.8711 60.8 15.1243 60.8 17.0214C60.8 17.2279 60.7897 17.4345 60.7553 17.6411H56.1004C56.1796 18.5844 56.8235 19.1112 57.6291 19.1112C58.3177 19.1112 58.6964 18.7669 58.903 18.34H60.6382C60.2939 19.5175 59.2369 20.4471 57.6635 20.4471ZM56.1108 16.5635H59.1337C59.1096 15.7234 58.4451 15.1966 57.6153 15.1966C56.8476 15.1966 56.2382 15.689 56.1108 16.5635Z"
      fill="white"
    />
    <path
      d="M19.2542 11.8398H19.8601V12.0258H19.6776V12.642H19.4366L19.4401 12.0258H19.2542V11.8398ZM19.9875 11.8398H20.3042L20.497 12.3563L20.7071 11.8398H21.0032V12.6455H20.7828L20.7794 12.1394L20.5728 12.6455H20.411L20.2044 12.1222V12.6455H19.9909V11.8398H19.9875Z"
      fill="#B9A096"
    />
    <path
      d="M14.8783 14.1122V11.8398H13.2705V13.3892C13.8351 13.5751 14.3722 13.8195 14.8783 14.1122Z"
      fill="#B9A096"
    />
    <path
      d="M14.0762 17.741C14.0177 17.6859 13.9557 17.6343 13.8972 17.5826C13.8903 17.5792 13.8869 17.5723 13.88 17.5689C13.8215 17.5207 13.7629 17.4725 13.701 17.4243C13.6906 17.4174 13.6837 17.4105 13.6734 17.4036C13.6149 17.3588 13.5529 17.3141 13.4909 17.2728C13.4806 17.2659 13.4737 17.259 13.4634 17.2555C13.4014 17.2142 13.336 17.1729 13.2706 17.1316L13.2671 17.1282H8.02703V11.8398H6.41919V20.3438H8.02703V18.736H13.2706V20.3438H14.8784V18.705C14.6512 18.3504 14.3792 18.0268 14.0762 17.741Z"
      fill="#B9A096"
    />
    <path
      d="M16.4861 11.8398V16.653C15.1021 14.9591 12.9985 13.878 10.647 13.878C10.0203 13.878 9.41095 13.9538 8.8291 14.0984V15.7717C9.40063 15.5857 10.0135 15.4859 10.647 15.4859C13.5528 15.4859 15.9732 17.5826 16.4827 20.3438H18.0974V11.8398H16.4861Z"
      fill="#B9A096"
    />
    <path
      d="M4.80779 16.653V11.8398H3.19995V20.3438H4.81468C4.95239 19.6002 5.22783 18.9047 5.61343 18.285V15.8095C5.32423 16.0712 5.05568 16.3535 4.80779 16.653Z"
      fill="#B9A096"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
